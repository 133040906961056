$(document).ready(function() {

    var flickerFlame = function() {
      var tl1 = gsap.timeline({repeat: -1, yoyo: true});
      var tl2 = gsap.timeline({repeat: -1, yoyo: true});
      var tl3 = gsap.timeline({repeat: -1, yoyo: false});
      var tl4 = gsap.timeline({repeat: -1, yoyo: true});

      tl1.fromTo(
        "#fire-triangle", 
        {fill:"#ed943b"},
        {scaleY: 1.15, ease:"bounce", fill:"#ed533b", transformOrigin:"bottom", duration: 1},
        "0"
      );
      tl4.fromTo(
        "#fire-glow", 
        {scale: .8,transformOrigin:"center"},
        {scale: 1, color:"#f27430", ease:"power1.out", transformOrigin:"center", duration: 4},
        "0"
      );
      tl4.fromTo(
        ".fire-light", 
        {scale: .7},
        {scale: 1.2, color:"#f27430",  ease:"power1.out", transformOrigin:"center", duration: 4},
        "0"
      );
      tl2.fromTo(
        "#fire-triangle2", 
        {scale: .4, color:"#edbb3b", transformOrigin:"bottom"}, 
        {scale: .5, ease:"bounce", fill:"#edd23b", transformOrigin:"bottom", duration: 1.5},
        ".2"
      );
      tl3.fromTo(
        "#fire-bubble-2",
        {opacity: 0, scale: 1.1, y:20,transformOrigin:"bottom"}, 
        {opacity: 100, scale: 0, y: -150, ease:"expo4.out", transformOrigin:"bottom", duration: 3},
        "1"
      );
    }
    
    flickerFlame();
});